@import '@scss/service';

.container {
  /*position: absolute;
      top: 0;*/
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.13em;
  background: #e1e1e1;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.hide {
  // transition: all 0.3s ease-in-out;
  height: 0;
}

.boxClose {
  align-self: flex-start;
  margin: 0.63em 0.31em;
}

.close {
  border: none;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  // line-height: 15px;
  color: #373742;
  background: #ffffff;
  font-weight: 600;

  display: inline-flex;
  align-items: flex-end;
  justify-content: space-around;
}

.logo {
  // height: 80%;
  vertical-align: middle;
}

.boxText {
  flex-grow: 1;
  margin: 0 0.31em;
}

.text {
  color: #373742;
  font-size: 0.85em;
  font-weight: 500;
  &Title {
    @extend .text;
    font-size: 1em;
    font-weight: 600;
  }
  &Bold {
    font-weight: bold;
  }
}

.boxButton {
  text-align: right;
  padding-right: 0.31em;
}

.button {
  color: #fff;
  background-color: #009dde;
  border: 1px solid #dddcdc;
  border-radius: 0px;
  font-weight: 400;
  padding: 0.19em 0.63em;
  min-height: 1.75em;
}

/* Temporary do not use
.app-banner_hide {
  animation: hide-app-banner 0.2s ease 0s 1 normal forwards;
}

@keyframes show-app-banner {
  from {
    max-height: 0;
  }

  to {
    max-height: 100px;
  }
}

@keyframes hide-app-banner {
  from {
    max-height: 100px;
  }

  to {
    max-height: 0;
  }
}
*/
