@import '@scss/service/';

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  z-index: 100;
}
