@import '@scss/service';

.app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // &Button {
  //   margin-bottom: 76px;
  //   &Redesign {
  //     margin-bottom: 104px;
  //   }
  // }
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.background {
  background: linear-gradient(
    to top,
    lighten($orange, 20%),
    lighten($orange, 100%) 40%
  );

  @include respond(sm) {
    background: linear-gradient(
      to top,
      lighten($orange, 20%),
      30%,
      lighten($orange, 100%) 50%
    );
  }

  @include respond(xs) {
    background: linear-gradient(
      to top,
      lighten($orange, 20%),
      40%,
      lighten($orange, 100%) 60%
    );
  }
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex-grow: 1;
}

.environmentInfo {
  color: $red;
  font-size: 2.5em;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.fontFira {
  font-family: 'Fira Sans', sans-serif;
}

.footerfloatPanelGap {
  padding-bottom: 185px;
}
