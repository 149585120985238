@import '@scss/service/';
$tab: $xxs, $xl;

.holder {
  transition: all 0.2s;

  @media (max-width: 1200px) {
    height: 43px;
  }

  @media (min-width: 1201px) {
      height: 88px;
  }

  &Pinned {
    .header {
      top: 0;
    }
  }

  /* &Compressed {
    min-height: 3.06em;

    .header {
      position: fixed;
      top: 0;
      z-index: 9;
    }
  } */

  &Blur {
    @extend %hasBlur;
  }
}

.header {
  position: fixed;
  // top: 0;
  z-index: 9;
  width: 100%;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
