@import '@scss/service';

.developerMenu {
  user-select: none;
  position: fixed;
  background-color: $black;
  color: $white;
  top: 0;
  right: 20%; //0;
  padding: 6px;
  border-radius: 5px;
  opacity: 0.7;
  z-index: 9999;

  &:hover {
    .list {
      height: auto;
    }
  }

  &.scrolled {
    height: 100%;
    overflow-y: scroll;
  }

  .list {
    list-style: none;
    height: 0;
    overflow: hidden;
  }

  .sublist {
    padding-left: 15px;
    list-style: disc;
  }

  .item {
    margin: 5px 0;
  }

  .link {
    color: $white;
    background-color: transparent;
    cursor: pointer;
    border: none;
    width: 100%;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }

  .button {
    color: $white;
    background-color: $black;
    transition: background-color 0.2s, color 0.2s;
    cursor: pointer;
    padding: 0.3em;
    user-select: none;
    border: 1px solid $white;
    border-radius: 7px;
    width: 100%;

    &.current,
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}
