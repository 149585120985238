@import '@scss/service';

.environmentInfo {
  font-size: 1.5em;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  position: fixed;
  width: 100%;
  pointer-events: none;
  z-index: 999;
  color: $red;
  background-color: transparentize($red, 0.8);

  @include respond(md) {
    font-size: 0.8em;
  }
}
