@import '@scss/service';

.generatedAttachment {
  // color: $red;
  // text-decoration: none;
  // border: none;
  // background-color: transparent;
  cursor: pointer;

  /* &:hover {
    color: $red-dark;
  } */
}

.link {
  color: $blue;
}
